// Import react libraries and components
import React from 'react';
// import { TextInput } from '../allTypesInputComponents/AllTypeInputComponents';
// import MultiSelectDropdown from '../dropdown/MultiSelectDropdown';
// import ValidationText from '../../utils/validations/ValidationText';
// import ButtonLoader from '../UIComponent/ButtonLoader';
// import { Image } from 'next/image';
import MultiSelectDropdown from '../../dropdown/MultiSelectDropdown';
import ValidationText from '../../../utils/validation/ValidationText';
import ButtonLoader from '../../common/buttonLoader/ButtonLoader';
import moment from 'moment';
import { TextInput } from '../../allTypesInputComponents/AllTypeInputComponents';
import DatePicker from '../../datePicker/DatePicker';

export default function CONQUASQualificationsProfile(props) {
  return (
    <>
      <div className='container mx-auto pb-10'>
        <div className="2xl:px-10 2xl:py-10 2xl:pt-7 lg:px-7 lg:py-7 lg:pt-5">
          <div className='grid grid-cols-12 gap-4'>
            <div className='col-span-12'>
              <div className='md:mt-5 mt-5 bg-gray-box  '>
                <div className='form-info-title'>
                  <h4 className='text-[#2d2d2d] form-info-title-txt text-[14px]'>
                    <b> Education Information (Academic/Professional Qualifications and Industrial Training) </b>
                  </h4>
                </div>
                <div className='col-span-12 '>
                  <div className="mb-2" >
                    {/* <ValidationText error={props.validState.error.authorisedRepresentativeSummaryList} /> */}
                  </div>
                  <div className='table-section'>
                    <div className='overflow-x-auto'>
                      <table className='w-full'>
                        <thead className='bg-[#4687C6] text-white'>
                          <tr>
                            <th className='text-left py-3 px-5'>
                              Name of Institution
                            </th>
                            <th className='text-left py-3 px-5'>
                              Academic/Professional Qualification
                            </th>
                            <th className='text-left py-3 px-5'>
                              Duration of Course Start Date
                            </th>
                            <th className='text-left py-3 px-5'>
                              Duration of Course End Date
                            </th>
                            <th className='text-left py-3 px-5'>
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody className="text-sm custom-tbody">
                          {
                            props.educationalInformation && props.educationalInformation.educationalInformation1.map((eduInfo, key) => {
                              return (
                                <tr className='bg-[#eeeeee] text-black border-t-4 border-[#ffffff]' key={key} >
                                  <td className='text-left py-2 px-5'>
                                    {eduInfo.nameOfInstitution}
                                  </td>
                                  <td className='text-left py-2 px-5'>
                                    {eduInfo.academicProfessionalQualificationsName}
                                  </td>
                                  <td className='text-left py-2 px-5'>
                                    {moment(eduInfo.durationOfCourseStartDate).format("DD-MMM-yyyy")}
                                    {/* {eduInfo.durationOfCourseStartDate} */}
                                  </td>
                                  <td className='text-left py-2 px-5'>
                                    {moment(eduInfo.durationOfCourseEndDate).format("DD-MMM-yyyy")}
                                    {/* {eduInfo.durationOfcourseEndDate} */}
                                  </td>
                                  <td className='py-2 px-5 text-center'>
                                    <div className='flex gap-2'>
                                      <button
                                        className='action-btn flex gap-2'
                                        onClick={() => props.getAcademicQualifications(key)}
                                      >
                                        <img className='min-h-[30px] min-w-[30px] mx-auto' src='../edit.png' alt='' />
                                      </button>


                                      <button
                                        className='action-btn flex gap-2'
                                        onClick={() => props.deleteConquasAcademicQualifications(eduInfo.academicProfessionalQualificationsIndustrialTrainingId)}
                                      >
                                        <img className='min-h-[30px] min-w-[30px] mx-auto' src='../delete-icon.svg' alt='' />
                                      </button>
                                    </div>
                                  </td>
                                  {/* <td className='text-left py-2 px-5'>
                                    <span className="flex items-center">
                                      <button
                                        className="action-btn mr-4 flex items-center"
                                        aria-controls="add-edit-modal"
                                        onClick={() => {
                                          props.getCONQUASMemberDetailByMemberId(key);
                                        }}
                                      >
                                        <p className="ml-2 tool-tip-txt underline text-[#005b9c]">
                                          Edit
                                        </p>
                                      </button>
                                    </span>
                                  </td> */}
                                </tr>
                              )
                            })
                          }
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-12 md:gap-10 md:gap-6 gap-3 w-full justify-between mt-5 mb-5">
                  <div className="lg:col-span-6 col-span-12 input-sec-texts">
                    <h2 className="input-title mb-3">
                      Name of Institution
                      <span className="text-[#C00000]"> *</span>
                    </h2>
                    <div className="dropdown">
                      <TextInput
                        placeholder=""
                        className='w-full'
                        name="nameOfInstitution"
                        value={props.detail1.nameOfInstitution}
                        identity="Conquas_Academic_Qualifications"
                        handleChange={(e) => props.handleChange(e, "Conquas_Academic_Qualifications")}
                        onBlur={() => props.validateField("nameOfInstitution", "Education_Information")}
                      />
                      <ValidationText error={props.validStateCONQUASAcademicQualifications.error.nameOfInstitution} />
                    </div>
                  </div>
                  <div className="lg:col-span-6 col-span-12 input-sec-texts">
                    <h2 className="input-title mb-3">
                      Academic/Professional Qualifications
                      <span className="text-[#C00000]"> *</span>
                    </h2>
                    <div className="dropdown">
                      <div className="select-dropdown w-full">
                        <MultiSelectDropdown
                          drpIdentity="Course_Duration"
                          options={props.academicQualificationList}
                          // isMulti
                          closeMenuOnSelect={true}
                          hideSelectedOptions={false}
                          onChange={props.handleChangeInDropdown}
                          allowSelectAll={false}
                          value={props.academicQualificationList.filter(duration => duration.value === props.detail1.academicProfessionalQualificationsId)}
                        />
                        <ValidationText error={props.validStateCONQUASAcademicQualifications.error.academicProfessionalQualificationsId} />
                      </div>
                      {/* <TextInput
                      placeholder=""
                      name="academyQualification"
                      value={props.detail1.academicProfessionalQualificationsName}
                      identity="Conquas_Application"
                      // handleChange={(e) => props.handleChange(e, "Educational_Information1")}
                      // onBlur={() => props.validateField("academyQualification", "Education_Information")}
                    /> */}
                      {/* <ValidationText error={props.validStateEducationInformation.error.academicProfessionalQualificationsName} />  */}
                    </div>
                  </div>
                  <div className="lg:col-span-6 col-span-12 input-sec-texts">
                    <h2 className="input-title mb-3">
                      Duration of Course
                      <span className="text-[#C00000]"> *</span>
                    </h2>
                    <div className="grid grid-cols-12 gap-6 items-center w-full">
                      <div className="2xl:col-span-5 lg:col-span-5 col-span-12 w-full">
                        <DatePicker
                          id="default"
                          name="durationOfCourseStartDate"
                          className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                          value={moment(props.detail1.durationOfCourseStartDate).format("YYYY-MM-DD")}
                          identity="durationOfCourseStartDate"
                          handleChange={(e) => props.handleChange(e, "Conquas_Academic_Qualifications")}
                        />
                        <ValidationText error={props.validStateCONQUASAcademicQualifications.error.durationOfCourseStartDate} />
                      </div>
                      <div className="2xl:col-span-1 lg:col-span-1 col-span-12 w-full text-center">
                        <h2 className="text-xl theme-color">To</h2>
                      </div>
                      <div className=" 2xl:col-span-5 lg:col-span-5 col-span-12 w-full">
                        <DatePicker
                          id="default"
                          name="durationOfCourseEndDate"
                          className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                          // type="date"
                          identity="durationOfCourseEndDate"
                          value={moment(props.detail1.durationOfCourseEndDate).format("YYYY-MM-DD")}
                          handleChange={(e) => props.handleChange(e, "Conquas_Academic_Qualifications")}
                          isDisable={props.detail1.durationOfCourseStartDate === "" ? true : false}
                          minDate={props.detail1.durationOfCourseStartDate}
                        />
                        <ValidationText error={props.validStateCONQUASAcademicQualifications.error.durationOfCourseEndDate} />
                      </div>
                    </div>
                    {/* <div className="select-dropdown w-full">
                      <MultiSelectDropdown
                      drpIdentity="Course_Duration"
                      options={props.courseDurationList}
                      // isMulti
                      closeMenuOnSelect={true}
                      hideSelectedOptions={false}
                      // onChange={props.handleChangeSelectorDropdown}
                      allowSelectAll={false}
                      value={props.courseDurationList.filter(duration => duration.value === props.detail1.courseDurationId)}
                    />
                      <ValidationText error={props.validStateEducationInformation.error.courseDurationId} />
                    </div>  */}
                  </div>
                  <div className="lg:col-span-6 col-span-12 input-sec-texts">
                    <h2 className="input-title mb-3">
                      Upload Academic Qualifications and Course Certificates
                      <span className="text-[#C00000]"> *</span>
                    </h2>
                    <div className="flex item-center flex-col">
                      {
                        props && props.isUploadAcademicQualifications ?
                          <ButtonLoader type='btn btn-blue' />
                          :
                          (
                            <>
                              {/* <form className="custom-uploader custom-file-upload-btn flex">
                                <div className='border border-[#f3f3f3] btn btn-conquas cursor-pointer'>
                                  <label
                                    htmlFor={`Academy_Qualification_Training`}
                                    // className="text-uplod text-white cursor-pointer"
                                    className="text-xl text-white"
                                  >
                                    Upload File
                                  </label>
                                  <input
                                    className="sr-only"
                                    id={`Academy_Qualification_Training`}
                                    type="file"
                                    onChange={(event) => props.onFileChange(event, "Academy_Qualification_Training")}
                                  />
                                </div>
                              </form> */}
                              <form className={`custom-uploader custom-file-upload-btn flex cursor-pointer lg:ml-2 md:mt-0 mt-2 `}>
                                <div className={`border border-[#f3f3f3] btn btn-blue lg:w-[185px]`}>
                                  <label
                                    htmlFor="Academy_Qualification_Training"
                                    className={`text-xl text-white`}>Upload File</label>
                                  <input
                                    className="sr-only"
                                    id="Academy_Qualification_Training"
                                    type="file"
                                    onChange={(event) => props.onFileChange(event, "Academy_Qualification_Training")}
                                  />
                                </div>
                              </form>
                              <ValidationText error={props.validStateCONQUASAcademicQualifications.error.academicQualificationsAndCourseCertificate} />
                            </>
                          )
                      }
                      {
                        props && props.detail1.academicQualificationsAndCourseCertificate ?
                          (
                            <div className='mt-5 file-uploaded-txt flex items-center'>
                              {/* <Image src='/check-orange.png' className='w-[25px] mr-3' alt='' /> */}
                              <span className='text-[#000]'>
                                {props.detail1.academicQualificationsAndCourseCertificate} <button className='underline text-[#e74c3c] font-[500]'
                                  onClick={() => props.GetCONQUASMemberFiles('AcademyAndProfessionalQualification_IndustrialTraining', props.detail1.academicQualificationsAndCourseCertificate)}
                                >
                                  View File
                                </button>
                              </span>
                            </div>
                          )
                          :
                          null
                      }
                      {/* <ValidationText error={props.validStateEducationInformation.error.academicQualificationsAndCourseCertificate} /> */}
                      {/* {
                        props.detail1.academicQualificationsAndCourseCertificate ? (
                          <button className='underline text-[#e74c3c] font-[500]'
                            onClick={() => props.GetCONQUASMemberFiles('AcademyAndProfessionalQualification_IndustrialTraining', props.detail1.academicQualificationsAndCourseCertificate)}
                          >
                            View File
                          </button>
                        ) : null
                      } */}
                    </div>
                  </div>
                  <div className="col-span-12">
                    <div className='flex justify-end'>
                      {/* {props.detail1.academicProfessionalQualificationsIndustrialTrainingId > 0 ?
                        <>
                        </>
                        :
                        <>
                        </>
                      } */}
                      <button
                        className='btn btn-border-conquas'
                        onClick={() => props.handleAddRemoveEducationalInfo("HandleAdd", "EducationalInfo1")}
                      >
                        <span className='fa fa-plus'></span>
                        <span className='ml-2'>
                          {props.detail1.academicProfessionalQualificationsIndustrialTrainingId > 0 ? "Update" : "Add"}
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
                <div className='form-info-title'>
                  <h4 className='text-[#2d2d2d] form-info-title-txt text-[14px]'>
                    <b> Education Information (CONQUAS Training Workshop) </b>
                  </h4>
                </div>
                <div className='col-span-12 '>
                  <div className="mb-2" >
                    {/* <ValidationText error={props.validState.error.authorisedRepresentativeSummaryList} /> */}
                  </div>
                  <div className='table-section'>
                    <div className='overflow-x-auto'>
                      <table className='w-full'>
                        <thead className='bg-[#4687C6] text-white'>
                          <tr>
                            <th className='text-left py-3 px-5'>
                              Name of Institution
                            </th>
                            {/* <th className='text-left py-3 px-5'>
                              Workshop Title
                            </th> */}
                            <th className='text-left py-3 px-5'>
                              CONQUAS Training Workshop for
                            </th>
                            <th className='text-left py-3 px-5'>
                              Duration of Workshop Start Date
                            </th>
                            <th className='text-left py-3 px-5'>
                              Duration of Workshop End Date
                            </th>
                            <th className='text-left py-3 px-5'>
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody className="text-sm custom-tbody">
                          {
                            props.educationalInformation && props.educationalInformation.educationalInformation2.map((eduInfo2, key) => {
                              return (
                                <tr className='bg-[#eeeeee] text-black border-t-4 border-[#ffffff]' key={key}>
                                  <td className='text-left py-2 px-5'>
                                    {eduInfo2.nameOfInstitutionForTrainingWorkshop}
                                  </td>
                                  {/* <td className='text-left py-2 px-5'>
                                    {eduInfo2.workshopTitle}
                                  </td> */}
                                  <td className='text-left py-2 px-5'>
                                    {eduInfo2.conquasTrainingWorkshopForName}
                                  </td>
                                  <td className='text-left py-2 px-5'>
                                    {/* {eduInfo2.durationOfCourseStartDate} */}
                                    {moment(eduInfo2.durationOfCourseStartDate).format("DD MMM yyyy")}
                                  </td>
                                  <td className='text-left py-2 px-5'>
                                    {/* {eduInfo2.durationOfcourseEndDate} */}
                                    {moment(eduInfo2.durationOfCourseEndDate).format("DD MMM yyyy")}
                                  </td>
                                  <td className='py-2 px-5 text-center'>
                                    <div className='flex gap-2'>
                                      <button
                                        className='action-btn flex gap-2'
                                        onClick={() => props.getTrainingWorkshop(key)}
                                      >
                                        <img className='min-h-[30px] min-w-[30px] mx-auto' src='../edit.png' alt='' />
                                      </button>
                                      <button
                                        className='action-btn flex gap-2'
                                        onClick={() => props.deleteConquasTrainingWorkshop(eduInfo2.conquasTrainingWorkshopId)}
                                      >
                                        <img className='min-h-[30px] min-w-[30px] mx-auto' src='../delete-icon.svg' alt='' />
                                      </button>
                                    </div>
                                  </td>
                                  {/* <td className='py-2 px-5 text-center'>
                                    <button
                                      className='action-btn flex gap-2'
                                      onClick={() => props.handleAddRemoveEducationalInfo("HandleRemove", "EducationalInfo2", key)}
                                    >
                                    </button>
                                  </td> */}
                                </tr>
                              )
                            })
                          }

                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-12 md:gap-10 md:gap-4 gap-3 w-full justify-between mt-5">
                  <div className="lg:col-span-6 col-span-12 input-sec-texts">
                    <h2 className="input-title mb-3">
                      Name of Institution
                      <span className="text-[#C00000]"> *</span>
                    </h2>
                    <div className="dropdown">
                      <TextInput
                        placeholder=""
                        className='w-full'
                        name="nameOfInstitution"
                        value={props.detail2.nameOfInstitutionForTrainingWorkshop}
                        identity="Conquas_Training_Workshop"
                        handleChange={(e) => props.handleChange(e, "Conquas_Training_Workshop")}
                        onBlur={() => props.validateField("nameOfInstitutionForTrainingWorkshop", "Educational_Information_Workshop")}
                      />
                    </div>
                    <ValidationText
                      error={props.validStateCONQUASTrainingWorkshop.error.nameOfInstitutionForTrainingWorkshop}
                    />
                  </div>
                  {/* <div className="lg:col-span-6 col-span-12 input-sec-texts">
                    <h2 className="input-title mb-3">
                      Workshop Title
                      <span className="text-[#C00000]"> *</span>
                    </h2>
                    <div className="dropdown">
                      <TextInput
                        placeholder=""
                        className='w-full'
                        name="workshopTitle"
                        value={props.detail2.workshopTitle}
                        identity="Conquas_Training_Workshop"
                        handleChange={(e) => props.handleChange(e, "Conquas_Training_Workshop")}
                        onBlur={() => props.validateField("workshopTitle", "Educational_Information_Workshop")}
                      />
                      <ValidationText error={props.validStateCONQUASTrainingWorkshop.error.workshopTitle} />
                    </div>
                  </div> */}
                  <div className="lg:col-span-6 col-span-12 input-sec-texts">
                    <h2 className="input-title mb-3">
                      CONQUAS Training Workshop For
                      <span className="text-[#C00000]"> *</span>
                    </h2>
                    <div className="dropdown">
                      <div className="select-dropdown w-full">
                        <MultiSelectDropdown
                          drpIdentity="Training_Workshop"
                          options={props.trainingWorkshopList}
                          // isMulti
                          closeMenuOnSelect={true}
                          hideSelectedOptions={false}
                          onChange={props.handleChangeInDropdown}
                          allowSelectAll={false}
                          value={props.trainingWorkshopList.filter(trainingWorkshop => trainingWorkshop.value === props.detail2.conquasTrainingWorkshopForId)}
                        />
                        <ValidationText error={props.validStateCONQUASTrainingWorkshop.error.conquasTrainingWorkshopForId} />
                      </div>
                    </div>
                  </div>
                  <div className="lg:col-span-6 col-span-12 input-sec-texts">
                    <h2 className="input-title mb-3">
                      Duration of Course
                      <span className="text-[#C00000]"> *</span>
                    </h2>
                    <div className="grid grid-cols-12 gap-6 items-center w-full">
                      <div className="2xl:col-span-5 lg:col-span-5 col-span-12 w-full">
                        <DatePicker
                          id="default"
                          name="durationOfCourseStartDate"
                          className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                          value={moment(props.detail2.durationOfCourseStartDate).format("YYYY-MM-DD")}
                          handleChange={(e) => props.handleChange(e, "Conquas_Training_Workshop")}
                        />
                        <ValidationText error={props.validStateCONQUASTrainingWorkshop.error.durationOfCourseStartDate} />
                      </div>
                      <div className="2xl:col-span-1 lg:col-span-1 col-span-12 w-full text-center">
                        <h2 className="text-xl theme-color">To</h2>
                      </div>
                      <div className=" 2xl:col-span-5 lg:col-span-5 col-span-12 w-full">
                        <DatePicker
                          id="default"
                          name="durationOfcourseEndDate"
                          // min={props.detail2.durationOfCourseStartDate}
                          className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                          value={moment(props.detail2.durationOfcourseEndDate).format("YYYY-MM-DD")}
                          handleChange={(e) => props.handleChange(e, "Conquas_Training_Workshop")}
                          isDisable={props.detail2.durationOfCourseStartDate === "" ? true : false}
                          minDate={props.detail2.durationOfCourseStartDate}
                        />
                        <ValidationText error={props.validStateCONQUASTrainingWorkshop.error.durationOfcourseEndDate} />
                      </div>
                    </div>
                    {/* <div className="select-dropdown w-full">
                      <MultiSelectDropdown
                      drpIdentity="Course_Duration2"
                      options={props.courseDurationList}
                      // isMulti
                      closeMenuOnSelect={true}
                      hideSelectedOptions={false}
                      // onChange={props.handleChangeSelectorDropdown}
                      allowSelectAll={false}
                      value={props.courseDurationList.filter(duration => duration.value === props.detail2.courseDurationId)}
                    />
                  </div>*/}
                  </div>
                  <div className="lg:col-span-6 col-span-12 input-sec-texts">
                    <h2 className="input-title mb-3">
                      Upload Academic Qualifications and Course Certificates
                      <span className="text-[#C00000]"> *</span>
                    </h2>
                    <div className="flex item-center flex-col">
                      {
                        props && props.isUploadAcademicQualificationsConquasTraining ?
                          <ButtonLoader type='btn btn-blue' />
                          :
                          (
                            <>
                              <form className={`custom-uploader custom-file-upload-btn flex cursor-pointer lg:ml-2 md:mt-0 mt-2 `}>
                                <div className={`border border-[#f3f3f3] btn btn-blue lg:w-[185px]`}>
                                  <label
                                    htmlFor={`Academy_Qualification_Workshop`}
                                    className={`text-xl text-white`}
                                  >
                                    Upload File
                                  </label>
                                  <input
                                    className="sr-only"
                                    id="Academy_Qualification_Workshop"
                                    type="file"
                                    onChange={(event) => props.onFileChange(event, "Academy_Qualification_Workshop")}
                                  />
                                </div>
                              </form>
                              <ValidationText error={props.validStateCONQUASTrainingWorkshop.error.academicQualificationAndCourseCertificates} />
                            </>
                          )
                      }
                      {
                        props && props.detail2.academicQualificationAndCourseCertificates ?
                          (
                            <div className='mt-5 file-uploaded-txt flex items-center'>
                              {/* <Image src='/check-orange.png' className='w-[25px] mr-3' alt='' /> */}
                              <span className='text-[#000]'>
                                {props.detail2.academicQualificationAndCourseCertificates} <button className='underline text-[#e74c3c] font-[500]'
                                  onClick={() => props.GetCONQUASMemberFiles('AcademyAndProfessionalQualification_Workshop', props.detail2.academicQualificationAndCourseCertificates)}
                                >
                                  View File
                                </button>
                              </span>
                            </div>
                          )
                          :
                          null
                      }
                    </div>
                  </div>
                  {/* <div className="lg:col-span-6 col-span-12 input-sec-texts">
                    <h2 className="input-title mb-3">
                      Upload Academic Qualifications and Course Certificates
                      <span className="text-[#C00000]"> *</span>
                    </h2>

                  </div> */}
                  <div className="col-span-12">
                    <div className='flex justify-end'>
                      <button
                        className='btn btn-border-conquas'
                        onClick={() => props.handleAddRemoveEducationalInfo("HandleAdd", "EducationalInfo2")}
                      >
                        <span className='fa fa-plus'></span>
                        <span className='ml-2'>
                          {props.detail2.conquasTrainingWorkshopId > 0 ? "Update" : "Add"}
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
                <div className='form-info-title'>
                  <h4 className='text-[#2d2d2d] form-info-title-txt text-[14px]'>
                    <b> Education Information (CONQUAS GIP Workshop) </b>
                  </h4>
                </div>
                {props.isValidateGIPCalculation && props.isValidateGIPCalculation === true ?
                <><h3 className='text-[#257d05] '>You have met the minimum criteria</h3></>
                :
                null
              }
                        {props.basicApplicationDetails.conquasLevelId === 1 || props.basicApplicationDetails.conquasLevelId === 4 ?
                <>
                  {/* <h6 className="text-[#C00000]" ></h6> */}
                  <div className='top-listing2'>
                    <ul>
                      {props.rulesForCONQUASSupervisorAndManager.isWaterproofingToInternalWetAreasSelected === false ?
                        <li className="text-[#C00000]">
                          {props.rulesForCONQUASSupervisorAndManager.waterproofingToInternalWetAreas}
                        </li>
                        :
                        null
                      }
                      {props.rulesForCONQUASSupervisorAndManager.isMiniAchievePoints === false ?
                        <li className="text-[#C00000]">
                          {props.rulesForCONQUASSupervisorAndManager.miniAchievePoints}
                        </li>
                        : null
                      }
                      {/* <li className="text-[#C00000]">
                        {props.rulesForCONQUASSupervisorAndManager.isMitMinimumCriteria === true ? null : props.rulesForCONQUASSupervisorAndManager.mitMinimumCriteria}
                      </li> */}
                    </ul>
                  </div>
                </>
                :
                <>
                  {props.basicApplicationDetails.conquasLevelId === 2 || props.basicApplicationDetails.conquasLevelId === 5 ?
                    <>
                      <div className='top-listing2'>
                        <ul>
                          {props.rulesForQualitySupervisorAndManager.isWaterproofingToInternalWetAreasSelected === false ?
                            <li className="text-[#C00000]">
                              {props.rulesForQualitySupervisorAndManager.waterproofingToInternalWetAreas}
                            </li>
                            :
                            null
                          }
                          {props.rulesForQualitySupervisorAndManager.isMarbleGraniteCeremicSelected === false ?
                            <li className="text-[#C00000]">
                              {props.rulesForQualitySupervisorAndManager.marbleGraniteCeremic}
                            </li>
                            :
                            null
                          }
                          {props.rulesForQualitySupervisorAndManager.isAluminiumWindowsSelected === false ?
                            <li className="text-[#C00000]">
                              {props.rulesForQualitySupervisorAndManager.aluminiumWindows}
                            </li>
                            :
                            null
                          }
                          {props.rulesForQualitySupervisorAndManager.isTimberFlooringSelected === false ?
                            <li className="text-[#C00000]">
                              {props.rulesForQualitySupervisorAndManager.timberFlooring}
                            </li>
                            :
                            null
                          }

                          {props.rulesForQualitySupervisorAndManager.isMiniAchievePoints === false ?
                            <li className="text-[#C00000]">
                              {props.rulesForQualitySupervisorAndManager.miniAchievePoints}
                            </li>
                            : null
                          }
                        </ul>
                      </div>
                    </>
                    :
                    null
                  }
                </>
              }
                  
              
                <div className='col-span-12 '>
                  <div className="mb-2" >
                    {/* <ValidationText error={props.validState.error.authorisedRepresentativeSummaryList} /> */}
                  </div>
                  <div className='table-section'>
                    <div className='overflow-x-auto'>
                      <table className='w-full'>
                        <thead className='bg-[#4687C6] text-white'>
                          <tr>
                            <th className='text-left py-3 px-5'>
                              Name of Institution
                            </th>
                            {/* <th className='text-left py-3 px-5'>
                              Workshop Title
                            </th> */}
                            <th className='text-left py-3 px-5'>
                              Good Industry Practices (GIP) Guide Workshops
                            </th>
                            <th className='text-left py-3 px-5'>
                              Duration of Workshop Start Date
                            </th>
                            <th className='text-left py-3 px-5'>
                              Duration of Workshop End Date
                            </th>
                            <th className='text-left py-3 px-5'>
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody className="text-sm custom-tbody">
                          {
                            props.educationalInformation && props.educationalInformation.educationalInformation3.map((eduInfo5, key) => {
                              return (
                                <tr className='bg-[#eeeeee] text-black border-t-4 border-[#ffffff]' key={key}>
                                  <td className='text-left py-2 px-5'>
                                    {eduInfo5.nameOfInstitution}
                                  </td>
                                  {/* <td className='text-left py-2 px-5'>
                                    {eduInfo5.workshopTitle}
                                  </td> */}
                                  <td className='text-left py-2 px-5'>
                                    {eduInfo5.conquasgipWorkshopForName}
                                  </td>
                                  <td className='text-left py-2 px-5'>
                                    {/* {eduInfo5.durationOfCourseStartDate} */}
                                    {moment(eduInfo5.durationOfCourseStartDate).format("DD MMM yyyy")}
                                  </td>
                                  <td className='text-left py-2 px-5'>
                                    {/* {eduInfo5.durationOfcourseEndDate} */}
                                    {moment(eduInfo5.durationOfCourseEndDate).format("DD MMM yyyy")}
                                  </td>
                                  <td className='py-2 px-5 text-center'>
                                    <div className='flex gap-2'>
                                      <button
                                        className='action-btn flex gap-2'
                                        onClick={() => props.getTrainingGIPWorkshop(key)}
                                      >
                                        <img className='min-h-[30px] min-w-[30px] mx-auto' src='../edit.png' alt='' />
                                      </button>
                                      <button
                                        className='action-btn flex gap-2'
                                        onClick={() => props.deleteConquasTrainingGIPWorkshop(eduInfo5.educationalProfessionalQualificationsGIPWorkshopId)}
                                      >
                                        <img className='min-h-[30px] min-w-[30px] mx-auto' src='../delete-icon.svg' alt='' />
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                              )
                            })
                          }

                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-12 md:gap-10 md:gap-4 gap-3 w-full justify-between mt-5">
                  <div className="lg:col-span-6 col-span-12 input-sec-texts">
                    <h2 className="input-title mb-3">
                      Name of Institution
                      <span className="text-[#C00000]"> *</span>
                    </h2>
                    <div className="dropdown">
                      <TextInput
                        placeholder=""
                        className='w-full'
                        type="text"
                        name="nameOfInstitution"
                        value={props.detail5.nameOfInstitution}
                        identity="Conquas_GIP_Workshop"
                        handleChange={(e) => props.handleChange(e, "Conquas_GIP_Workshop")}
                        onBlur={() => props.validateField("nameOfInstitution", "Educational_Information_GIP_Workshop")}
                      />
                      <ValidationText error={props.validStateCONQUASGIPWorkshop.error.nameOfInstitution} />
                    </div>
                  </div>
                  {/* <div className="lg:col-span-6 col-span-12 input-sec-texts">
                    <h2 className="input-title mb-3">
                      Workshop Title
                      <span className="text-[#C00000]"> *</span>
                    </h2>
                    <div className="dropdown">
                      <TextInput
                        placeholder=""
                        type="text"
                        className='w-full'
                        name="workshopTitle"
                        value={props.detail5.workshopTitle}
                        identity="Conquas_GIP_Workshop"
                        handleChange={(e) => props.handleChange(e, "Conquas_GIP_Workshop")}
                        onBlur={() => props.validateField("workshopTitle", "Educational_Information_GIP_Workshop")}
                      />
                      <ValidationText error={props.validStateCONQUASGIPWorkshop.error.workshopTitle} />
                    </div>
                  </div> */}
                  <div className="lg:col-span-6 col-span-12 input-sec-texts">
                    <h2 className="input-title mb-3">
                      Good Industry Practices (GIP) Guide Workshops
                      <span className="text-[#C00000]"> *</span>
                    </h2>
                    <div className="dropdown">
                      <div className="select-dropdown w-full">
                        <MultiSelectDropdown
                          drpIdentity="GIP_Workshop"
                          options={props.gipWorkshopList}
                          // isMulti
                          closeMenuOnSelect={true}
                          hideSelectedOptions={false}
                          onChange={props.handleChangeInDropdown}
                          allowSelectAll={false}
                          // value={props.detail5.conquasGIPWorkshopDropdownList}
                          value={props.gipWorkshopList.filter(gipWorkshop => gipWorkshop.value === props.detail5.conquasgipWorkshopForId)}
                        />
                        <ValidationText error={props.validStateCONQUASGIPWorkshop.error.conquasgipWorkshopForId} />
                      </div>
                    </div>
                  </div>
                  <div className="lg:col-span-6 col-span-12 input-sec-texts">
                    <h2 className="input-title mb-3">
                      Duration of Course
                      <span className="text-[#C00000]"> *</span>
                    </h2>
                    <div className="grid grid-cols-12 gap-6 items-center w-full">
                      <div className="2xl:col-span-5 lg:col-span-5 col-span-12 w-full">
                        <DatePicker
                          id="default"
                          name="durationOfCourseStartDate"
                          className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                          value={moment(props.detail5.durationOfCourseStartDate).format("YYYY-MM-DD")}
                          handleChange={(e) => props.handleChange(e, "Conquas_GIP_Workshop")}
                          // isDisable={props.detail5.durationOfCourseStartDate === "" ? true : false}
                           
                        />
                        <ValidationText error={props.validStateCONQUASGIPWorkshop.error.durationOfCourseStartDate} />
                      </div>
                      <div className="2xl:col-span-1 lg:col-span-1 col-span-12 w-full text-center">
                        <h2 className="text-xl theme-color">To</h2>
                      </div>
                      <div className=" 2xl:col-span-5 lg:col-span-5 col-span-12 w-full">
                        <DatePicker
                          id="default"
                          name="durationOfcourseEndDate"
                          className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                          value={moment(props.detail5.durationOfcourseEndDate).format("YYYY-MM-DD")}
                          handleChange={(e) => props.handleChange(e, "Conquas_GIP_Workshop")}
                          isDisable={props.detail5.durationOfCourseStartDate === "" ? true : false}
                          minDate={props.detail5.durationOfCourseStartDate}
                        />
                        <ValidationText error={props.validStateCONQUASGIPWorkshop.error.durationOfcourseEndDate} />
                      </div>
                    </div>
                    {/* <div className="select-dropdown w-full">
                      <MultiSelectDropdown
                      drpIdentity="Course_Duration2"
                      options={props.courseDurationList}
                      // isMulti
                      closeMenuOnSelect={true}
                      hideSelectedOptions={false}
                      // onChange={props.handleChangeSelectorDropdown}
                      allowSelectAll={false}
                      value={props.courseDurationList.filter(duration => duration.value === props.detail2.courseDurationId)}
                    />
                    </div> */}
                  </div>

                  <div className="lg:col-span-6 col-span-12 input-sec-texts">
                    <h2 className="input-title mb-3">
                      Upload Academic Qualifications and Course Certificates
                      <span className="text-[#C00000]"> *</span>
                    </h2>
                    <div className="flex item-center flex-col">
                      {
                        props && props.isUploadAcademicQualificationsConquasGIP ?
                          <ButtonLoader type='btn btn-blue' />
                          :
                          (
                            <>
                              <form className="custom-uploader custom-file-upload-btn flex">
                                <div className={`border border-[#f3f3f3] btn btn-blue lg:w-[185px]`}>
                                  <label
                                    htmlFor={`Academy_Qualification_GIP_Workshop`}
                                    className={`text-xl text-white`}
                                  >
                                    Upload File
                                  </label>
                                  <input
                                    className="sr-only"
                                    id="Academy_Qualification_GIP_Workshop"
                                    type="file"
                                    onChange={(event) => props.onFileChange(event, "Academy_Qualification_GIP_Workshop")}
                                  />
                                </div>
                              </form>
                              <ValidationText error={props.validStateCONQUASGIPWorkshop.error.academicQualificationsAndCourseCertificate} />
                            </>
                          )
                      }
                      {
                        props && props.detail5.academicQualificationsAndCourseCertificate ?
                          (
                            <div className='mt-5 file-uploaded-txt flex items-center'>
                              {/* <Image src='/check-orange.png' className='w-[25px] mr-3' alt='' /> */}
                              <span className='text-[#000]'>
                                {props.detail5.academicQualificationsAndCourseCertificate} <button className='underline text-[#e74c3c] font-[500]'
                                  onClick={() => props.GetCONQUASMemberFiles('AcademyAndProfessionalQualification_GIPWorkshop', props.detail5.academicQualificationsAndCourseCertificate)}
                                >
                                  View File
                                </button>
                              </span>
                            </div>
                          )
                          :
                          null
                      }
                    </div>
                  </div>
                  {/* <div className="lg:col-span-6 col-span-12 input-sec-texts">
                    <h2 className="input-title mb-3">
                      Upload Academic Qualifications and Course Certificates
                      <span className="text-[#C00000]"> *</span>
                    </h2>

                  </div> */}
                  <div className="col-span-12">
                    <div className='flex justify-end'>
                      <button
                        className='btn btn-border-conquas'
                        onClick={() => props.handleAddRemoveEducationalInfo("HandleAdd", "EducationalInfo3")}
                      >
                        <span className='fa fa-plus'></span>
                        <span className='ml-2'>
                          {props.detail5.educationalProfessionalQualificationsGIPWorkshopId > 0 ? "Update" : "Add"}
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div >
    </>
  )
}